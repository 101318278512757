<template>
  <ComponentWrapper :name="name" :classes="inputGroupClass" :id="id">

    <Multiselect v-model="inputValue"
                 :id="`${id}_input`"
                 v-bind="this.$attrs"
                 @focus="this.inputTouched = true"
                 :options="choiceIds"
                 :custom-label="displayNameLabel"
                 :multiple="true"
                 :options-limit="20"
                 select-label=""
                 deselectLabel=""
                 selectedLabel=""
                 :limit="limit"
                 :limit-text="limitText"
    />

    <input v-for="selected_id in inputValue" type="hidden" :id="`${id}_${selected_id}`" :name="inputName" :value="selected_id"/>
    <input v-if="inputValue.length === 0" type="hidden" :id="`${id}_empty`" :name="inputName"/>

    <ErrorsPlaceholder v-if='displayError' :names=[name]></ErrorsPlaceholder>
  </ComponentWrapper>
</template>

<script>
import { ErrorsPlaceholder } from "elevate_ds_rails";
import { ComponentWrapper } from "elevate_ds_rails";
import Multiselect from 'vue-multiselect'

export default {
  components: { ComponentWrapper, ErrorsPlaceholder, Multiselect },
  data () {
    return {
      selectedValues: null
    }
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      require: true
    },
    id: {
      type: String,
      require: true
    },
    limit: {
      type: [String, Number],
      require: true,
      default: 1
    },
    choices: {
      type: String,
      require: true
    },
    suggest_value: { // do not use directly (instead use the computed property suggestValue)
      type: [String, Boolean],
      require: false,
      default: false
    },
    display_error: { // do not use directly (instead use the computed property displayError)
      type: [String, Boolean],
      require: false,
      default: true
    }
  },
  computed: {
    choicesToJson: function () {
      return (
          this.$store.getters.getPotentialValues(this.$props.name) || JSON.parse(this.$props.choices)
      )
    },
    choiceIds: function () {
      return this.choicesToJson.map(i => i.id)
    },
    displayValidationError: function () {
      return this.inputTouched && this.inputError;
    },
    displayValidationWarning: function () {
      return this.inputTouched && !this.inputError && this.inputWarning;
    },
    suggestValue: function () {
      return (this.$props.suggest_value === 'true' || this.$props.suggest_value === true || this.$props.suggest_value === 'force')
    },
    displayError: function () {
      return (this.$props.display_error === 'true' || this.$props.display_error === true)
    },
    useSuggestedValue: function () {
      return (this.suggestValue && !this.inputTouched) || this.$props.suggest_value === 'force'
    },
    suggestedValue: function () {
      return this.$store.getters.getSuggestedValues(this.$props.name);
    },
    inputGroupClass: function () {
      return {}
    },
    inputError: function () {
      if (this.inputTouched) {
        return this.$store.getters.getError(this.$props.name);
      } else {
        return null;
      }
    },
    inputWarning: function () {
      if (this.inputTouched) {
        return this.$store.getters.getWarning(this.$props.name);
      } else {
        return null;
      }
    },
    inputTouched: {
      get () {
        return this.$store.getters.getTouched(this.$props.name);
      },
      set (value) {
        this.$store.commit('setTouched', {
          value: value,
          name: this.$props.name
        });
      }
    },
    inputValue: {
      get () {
        let value = this.$store.getters.getValue(this.$props.name) || [];

        return this.sanitizeArray(value);
      },
      set (value) {
        this.$store.dispatch('update', {
          value: value,
          name: this.$props.name
        });
      }
    },
    inputName: function () {
      return (this.$props.name + '[]')
    }
  },
  watch: {
    suggestedValue: function (value, _oldValue) {
      if (this.useSuggestedValue) {
        this.inputValue = value;
      }
    },
  },
  methods: {
    sanitizeArray (array) {
      return array.filter(item => item !== "");
    },
    limitText (count) {
      return `+${count}`
    },
    displayNameLabel(option) {
      const match = this.choicesToJson.find((item) => item.id === option);
      return match ? match.display_name : '';
    },
  },
}
</script>
